import React from "react"
import Layout from "../layouts"
import { ContactHeader } from "../components/contact-page/contact-header"
import { RequestInfo } from "../components/home-page/request-info"

const ContactPage = ({ data }) => {
  const content = data.allContentfulContact.edges[0].node

  return (
    <Layout>
      <ContactHeader
        imgSrc={content.headerImage.file.url}
        copy={[content.headerCopy, content.contactIntro.contactIntro]}
      />
      <img
        src={content.contactIntroImage.file.url}
        style={{
          width: "100%",
          height: "auto",
          margin: 0,
          verticalAlign: "middle",
        }}
      />
      <RequestInfo copy={content.contactForm.contactForm} />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query contactContent {
    allContentfulContact {
      edges {
        node {
          headerCopy
          contactIntro {
            contactIntro
          }
          contactForm {
            contactForm
          }

          headerImage {
            file {
              url
            }
          }
          contactIntroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
