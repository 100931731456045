import React from 'react'
import './styles.css';

export const ContactHeader = ({ imgSrc, copy }) => (
  <div>
    <div>
      <div className="contact-container" style={{ backgroundImage: `url(${imgSrc})` }}>
        <div className="h1-container">
          <h1 className="contact-h1">
              {copy[0]}
          </h1>
        </div>
      </div>

      <p className="contact-copy">
        {copy[1]}
      </p>
    </div>
  </div>
)
